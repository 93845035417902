import React from 'react'
import './findDunkin.scss'

const FindDunkin = () => {
	return (
		<div className={'find-dunkin'} >
	      <h5 className={'text'}>
	        FIND DUNKIN' CREAMERS AT A STORE NEAR YOU
	      </h5>
	      <form>
	        <input className={'input-zip'} placeholder='Zip Code' type='text'></input>
	        <input className={'input-submit'} type='submit' value='FIND' />
	      </form>
	      <p>Looking for other Dunkin' products? Click <a href='/'>here</a>.</p>
	    </div>
	)
}

export default FindDunkin;
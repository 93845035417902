import React from 'react';
import Swiper from 'react-id-swiper';
import '../../node_modules/swiper/dist/css/swiper.css';
import './carousel.scss';

import recipeIcon from "../assets/images/icons-logos/recipe icon.svg";
// import creamBorderMobile from "../assets/images/borders/cream border bottom right-mobile.png";
// import creamBorderDesk from "../assets/images/borders/cream border bottom right.png";

// import oneOff from "../assets/images/recipes/dunkin-recipe-carousel-off.jpg";
// import oneOver from "../assets/images/recipes/dunkin-recipe-carousel-over.jpg";
// import twoOff from "../assets/images/recipes/dunkin-recipe-featured-off.jpg";
// import twoOver from "../assets/images/recipes/dunkin-recipe-featured-over.jpg";
import muffin from "../assets/images/recipes/dunkin-recipegrid-off.png";
// import threeOver from "../assets/images/recipes/dunkin-recipegrid-over.jpg";
import original from "../assets/images/product/bottles/no glow/bottle-dunkin-original-32oz.png";

const data = [
  {
    alt: 'Easy Tres Leches Cake',
    hed: 'FIRST RECIPE HED',
    productImage: original,
    productName: 'Dunkin\' Donuts Original Coffee Creamer',
    recipeImage: muffin,
    // off: oneOff,
    // over: oneOver,
    text: '30 min | Serves 3-6',
  },
  {
    alt: 'Yum!',
    hed: 'SECOND RECIPE HED',
    productImage: original,
    productName: 'Dunkin\' Donuts Original Coffee Creamer',
    recipeImage: muffin,
    // off: threeOff,
    // over: threeOver,
    text: '60 min | Serves 6-8',
  },
  {
    alt: 'Recipe yay',
    hed: 'THIRD RECIPE HED',
    productImage: original,
    productName: 'Dunkin\' Donuts Original Coffee Creamer',
    recipeImage: muffin,
    // off: twoOff,
    // over: twoOver,
    text: '45 min | Serves 1-2',
  },
];

const params = {
  // effect: 'coverflow',
  autoplay: {
    delay: 5000
  },
  grabCursor: true,
  centeredSlides: true,
  containerClass: 'swiper-container carousel-container',
  slidesPerView: 'auto',  
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true
  },
  // slidesPerView: 1,
  spaceBetween: (20),
  loop: true,
  breakpoints: {
    2024: {
      // slidesPerView: 1,
      spaceBetween: 50
    },
    768: {
      // slidesPerView: 1,
      spaceBetween: 30
    },
    // 640: {
    //   // slidesPerView: 1,
    //   spaceBetween: 30
    // },
    // 320: {
    //   // slidesPerView: 1,
    //   spaceBetween: 10
    // }
  }
};

const carouselHome = () => {
  return (
    <Swiper {...params}>
      {data.map((slide, index) =>
        <div
          className='slide'
          key={`slide-${index}`}
        >
          <div className={'image-overlay'} />
          <div className={'recipe-image'} style={{backgroundImage: `url(${slide.recipeImage})`}} />
          <img alt='' className={'recipe-icon'} src={recipeIcon} />
          <img alt='Product Title' className={'product-image'} src={slide.productImage} />
          <h5 className={'off'}>{slide.hed}</h5>
          <h5 className={'hover'}>LET'S MAKE IT</h5>
          <p className={'off'}>{slide.text}</p>
          <p className={'hover'}>{slide.productName}</p>
        </div>
      )}
    </Swiper>
  )
}

export default carouselHome;
import { Link } from "gatsby"
import React from 'react';

import Carousel from '../../components/carousel';
import FindDunkin from '../../components/findDunkin';
import Layout from '../../components/layout';
import SEO from '../../components/seo';

import recipeIcon from "../../assets/images/icons-logos/recipe icon.svg";
import productVanilla from '../../assets/images/product/bottles/no glow/bottle-dunkin-vanilla-extra-extra-32oz.png';
import './recipeDetail.scss';

const RecipePage = () => {
	return (
		<Layout headerColor='yellow'>
  		<SEO title='Recipe' />

			<div className='recipe-detail'>
				<div className='hed'>
					<img alt='' className='recipe-icon' src={recipeIcon} />

					<div className='card'>
						<h1>EASY TRES LECHES CAKE</h1>
						<p className='subhed'>30 min | Serves 2-4</p>
						<p className='recipe-description'>
							A deceptively simple cake that is as rich as it is airy, delicious as it is simple.
						</p>
					</div>
				</div>

				<div className='flex-wrapper'>
					<div className='left'>
			  		<div className='instructions'>
			  			<div className='ingredients'>
			  				<h3>INGREDIENTS</h3>
			  				<ul>
			  					<li>1 cup <Link className='product'>Product Name</Link></li>
			  					<li>2 cups flour</li>
			  					<li>1 tsp. baking soda</li>
			  					<li>1 tbsp. baking powder</li>
			  					<li>8 tbsp. butter (1 stick)</li>
			  					<li>1/2 cup sugar</li>
			  					<li>1 tsp. vanilla extract</li>
			  				</ul>
			  			</div>

			  			<div className='directions'>
			  				<h3>DIRECTIONS</h3>
			  				<ol>
			  					<li>Lorem ipsum stuff goes here real quick</li>
			  					<li>Another step here please and thank you</li>
			  					<li>This will taste so good</li>
			  					<li>Make the stuff deliciously well please eat all of it</li>
			  					<li>Baking at altitude is the height of fun</li>
			  					<li>Almost finished with these instructions</li>
			  					<li>Burp with satisfaction</li>
			  				</ol>
			  			</div>	
			  		</div>

		  			<Link className='back'>&lt; BACK TO RECIPES</Link>
	  			</div>
	  			

		  		<div className='made-with-wrapper'>
		  			<img alt='Product Name Here' className='product-image' src={productVanilla} />
		  			<p className='made-with'>Made with</p>
		  			<Link className='product-name'>VANILLA EXTRA EXTRA</Link>
		  		</div>
	  		</div>
  		</div>

  		<Carousel />
			<FindDunkin />
		</Layout>
	)
}

export default RecipePage;